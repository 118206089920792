import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { verifyStripeSession } from './core/_requests';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { StripeCheckoutSession } from './core/_models';
import { useAuth } from './core/Auth';
import { HandleApiError } from '../../core/helpers/errorHandling';

// http://localhost:3011/auth/signup-success?session_id=cs_test_a11YL379gow47ywWj3EosBp8foaqWaJloGXO9jyKQBn5tvGq68fDjc9TIv

const SignupSuccessPage = () => {

    const location = useLocation()

    // Function to get query parameter value
    const getQueryParam = (param: any) => {
        const searchParams = new URLSearchParams(location.search)
        return searchParams.get(param)
    }

    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser, currentUser } = useAuth()
    const [errors, setErrors] = useState<Record<string, string>>()

    const handleErrors = (field: string, errorMessage: string) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
        }));
    };

    const fetchData = async () => {
        const sessionId = getQueryParam('session_id')
        console.log(sessionId)
        if (sessionId === null) return
        try {
            setLoading(true)
            const { data: auth } = await verifyStripeSession(sessionId)
            console.log(auth)
            saveAuth(auth)
            setCurrentUser(auth.user)
            setLoading(false)
        } catch (error) {
            console.error(error)
            handleErrors('serverError', HandleApiError(intl, error));
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [location.search]); // Added location.search as a dependency

    const handleButtonClick = () => {
        window.open('https://brainmix.ai/configurazione/attivazione.html?email=' + currentUser?.email + '&id=' + currentUser?._id, '_blank'); // Replace with your URL
    };

    return (
        <div className="d-flex flex-column flex-column-fluid bg-light">

            <div id="kt_app_content" className="flex-column-fluid" data-kt-app-page-loading-enabled="true" data-kt-app-page-loading={loading ? 'on' : 'off'}>
                <div className="page-loader flex-column bg-dark bg-opacity-25">
                    <span className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </span>
                </div>

                {errors?.serverError && (
                    <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            {errors?.serverError}
                        </div>
                    </div>
                )}

                <div
                    className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                    style={{ backgroundColor: `#F2F2F2` }}>

                    <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>


                        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                            <div className='w-100 text-center'>

                                <h1 className='fw-bold text-primary mb-2'>Benvenuto {currentUser?.name}!</h1>


                                <div className='text-muted fw-semibold fs-4'>
                                    Hai completato con successo la registrazione a BrainMix, ma non abbiamo ancora finito!
                                </div>
                                <div className='text-center px-4 py-5'>
                                    <img
                                        src={toAbsoluteUrl('/media/illustrations/sketchy-1/9.png')}
                                        alt=''
                                        className='mw-100 mh-300px'
                                    />
                                </div>

                                <div className='text-dark fw-semibold fs-3 mt-2'>
                                    Clicca sul pulsante che trovi sotto per completare la configurazione del tuo account
                                </div>

                                <button type="button" className="btn btn-lg btn-primary mt-4" onClick={handleButtonClick}>
                                    Completa configurazione
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export { SignupSuccessPage }
