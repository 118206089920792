import axios from 'axios'
import { PaginationModel } from '../../../core/models/wrapperModels'
import { EventModel, RequestTypeModel, SuggestionModel, UrlModel } from './_models'
import { MultiValue } from 'react-select'

const API_URL = process.env.REACT_APP_API_URL

export const DASHBOARD_ANALYSIS_EVENTS = `${API_URL}/dashboard/events`
export const DASHBOARD_ANALYSIS_RELEVANT_URLS = `${API_URL}/dashboard/relevant-urls`
export const DASHBOARD_ANALYSIS_PROJECT_URLS = `${API_URL}/dashboard/project-urls`
export const DASHBOARD_ANALYSIS_ADVICE = `${API_URL}/dashboard/advice`

export const DASHBOARD_EVENT_SUGGESTION = `${API_URL}/dashboard/event/suggestion`

export const ANALYSIS_UPDATE_URL = `${API_URL}/urls/`
export const ANALYSIS_ADD_URL = `${API_URL}/urls/add`
export const ANALYSIS_ADD_MY_URL = `${API_URL}/urls/add-my`

export const SETUP_REQUEST_TYPES_URL = `${API_URL}/setup/request-types`

export function getAnalysisEvents(page: number, limit: number) {
  return axios.get<PaginationModel<EventModel>>(DASHBOARD_ANALYSIS_EVENTS, { params: { page: page, limit: limit } })
}

export function getAnalysisAdvice(page: number, limit: number) {
  return axios.get<PaginationModel<EventModel>>(DASHBOARD_ANALYSIS_ADVICE, { params: { page: page, limit: limit } })
}

export function getAnalysisEventSuggestion(eventId: any) {
  return axios.get<SuggestionModel>(DASHBOARD_EVENT_SUGGESTION, { params: { eventId: eventId } })
}

export function getAnalysisRelevantUrls(page: number, limit: number) {
  return axios.get<PaginationModel<UrlModel>>(DASHBOARD_ANALYSIS_RELEVANT_URLS, { params: { page: page, limit: limit } })
}

export function getProjectUrls(page: number, limit: number) {
  return axios.get<PaginationModel<UrlModel>>(DASHBOARD_ANALYSIS_PROJECT_URLS, { params: { page: page, limit: limit } })
}

export function addMyUrl(url: string, description: string, typeUrl: string, requestTypes?: string[]) {
  const formData = new URLSearchParams();
  formData.append('url', url);
  formData.append('description', description);
  formData.append('typeUrl', typeUrl);
  formData.append('analyze', 'true');
  requestTypes?.forEach((type, index) => {
    formData.append(`types[${index}]`, type);
  });
  return axios.post<UrlModel>(ANALYSIS_ADD_MY_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function addUrl(url: string, description: string, typeUrl: string, competitor: string, requestTypes?: string[]) {
  const formData = new URLSearchParams();
  formData.append('url', url);
  formData.append('description', description);
  formData.append('typeUrl', typeUrl);
  formData.append('competitor', competitor);
  formData.append('analyze', 'true');
  requestTypes?.forEach((type, index) => {
    formData.append(`types[${index}]`, type);
  });
  return axios.post<UrlModel>(ANALYSIS_ADD_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function updateUrl(id: String, analyze: boolean, url?: string, description?: string, typeUrl?: string, requestTypes?: any[]) {
  const formData = new URLSearchParams();
  formData.append('analyze', analyze.toString())
  if (url != null) formData.append('url', url)
  if (description != null) formData.append('description', description)
  if (typeUrl != null) formData.append('typeUrl', typeUrl)
  if (requestTypes != null) requestTypes?.forEach((type, index) => {
    formData.append(`requestType[${index}]`, type.value);
  });
  return axios.patch<UrlModel>(ANALYSIS_UPDATE_URL + id, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function deleteUrl(id: String) {
  return axios.delete<UrlModel>(ANALYSIS_UPDATE_URL + id, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function getRequestTypes() {
  return axios.get<RequestTypeModel[]>(SETUP_REQUEST_TYPES_URL)
}