import { FC, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { ActivityDrawer, DrawerMessenger, InviteUsers, RightToolbar, UpgradePlan } from '../partials'
import { ThemeModeProvider, themeModeSwitchHelper, useThemeMode, } from '../partials/layout/theme-mode/ThemeModeProvider'

import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { WithChildren } from '../helpers'
import { Navbar } from './components/header/Navbar'
import { Toolbar } from './components/toolbar/Toolbar'
import { useAuth } from '../../app/modules/auth'

const MasterLayout: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout()
  const { mode } = useThemeMode()
  const location = useLocation()
  const { currentUser } = useAuth()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  useEffect(() => {
    if (currentUser !== undefined) {
      const script = document.createElement('script');
      script.src = 'https://widget.cxgenie.ai/widget.js';
      script.setAttribute('data-aid', '19d65c1a-c47b-4c2a-b8d9-8f4bf7d16167');
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            {/* <HeaderWrapper /> */}
            <Navbar />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              {/* <Toolbar /> */}
              <div
                className={clsx(
                  'd-flex flex-column-fluid align-items-start',
                  //classes.contentContainer.join(' ')
                )}
                id='kt_post'
              >
                <AsideDefault />
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        {/* <ActivityDrawer /> */}
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
