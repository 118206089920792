import axios from 'axios'
import { AuthModel, StripeCheckoutSession } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}/reset`
export const BUY_SUBSCRIPTION_URL = `${API_URL}/subscription/buy`
export const VERIFY_STRIPE_SESSION_URL = `${API_URL}/subscription/verify`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  }, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    }
  })
}

export function register(name?: string, surname?: string, email?: string, phone?: string, password?: string,
  company?: string, vatNumber?: string, address?: string, city?: string, zipcode?: string, country?: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    name,
    surname,
    email,
    phone,
    password,
    company,
    vatNumber,
    address,
    city,
    zipcode,
    country
  }, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    }
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function forgotPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resetPassword(id: string, password: string) {
  return axios.post<{ result: boolean }>(REQUEST_RESET_PASSWORD_URL, {
    id, password
  })
}

export function getUserByToken() {
  return axios.get<AuthModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function buySubscription(priceId?: string, planId?: string) {
  return axios.get<{ sessionUrl: string }>(BUY_SUBSCRIPTION_URL, { params: { priceId: priceId, planId: planId } })
}

export function verifyStripeSession(sessionId: string) {
  return axios.get<AuthModel>(VERIFY_STRIPE_SESSION_URL, { params: { sessionId: sessionId } })
}
