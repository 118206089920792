import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Suggestion from '../pages/analysis/Suggestion'
import MyProductsPage from '../pages/products/MyProductsPage'
import SuggestedProductsPage from '../pages/products/SuggestedProductsPage'
import ProductDetailsPage from '../pages/products/ProductDetailsPage'
import { SignupSuccessPage } from '../modules/auth/SignupSuccessPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const SignalsPage = lazy(() => import('../pages/analysis/Signals'))
  const RelevantUrlsPage = lazy(() => import('../pages/analysis/RelevantUrls'))
  const AdvicePage = lazy(() => import('../pages/analysis/Advice'))

  return (
    <Routes>
      <Route
        path='signup-success'
        element={
          <SignupSuccessPage />
        }
      />
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/analysis/signals' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Analysis */}
        <Route path='analysis/*' element={<Navigate to='/analysis/signals' />} />
        <Route path='analysis/signals' element={
          <SuspensedView>
            <SignalsPage />
          </SuspensedView>
        } />
        <Route path='analysis/relevant-urls' element={
          <SuspensedView>
            <RelevantUrlsPage />
          </SuspensedView>
        } />
        <Route path='products/*' element={<Navigate to='/products/mine' />} />
        <Route path='products/mine' element={
          <SuspensedView>
            <MyProductsPage />
          </SuspensedView>
        } />
        <Route path='products/suggested' element={
          <SuspensedView>
            <SuggestedProductsPage />
          </SuspensedView>
        } />
        <Route path='product/:productId' element={<ProductDetailsPage />} />
        <Route path='advice' element={
          <SuspensedView>
            <AdvicePage />
          </SuspensedView>
        } />
        <Route path='suggestion/:eventId' element={<Suggestion />} />
        {/* Lazy Modules */}
        <Route
          path='user/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
